import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import ContentsList from '../../../components/ContentsList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="カラー - スタイル" mdxType="SEO" />
    <PageTitle title="カラー" enTitle="Color" mdxType="PageTitle" />
    <p>{`Amebaの伝統的な緑を基調としたカラーを定義しています。UIに利用されるカラーには`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
      }}>{`WCAG2.1の達成基準 1.4.3`}</a>{`に基づき、十分なコントラスト比を担保するように設計されています。`}</p>
    <ContentsList list={[{
      url: "/styles/color/brand/",
      title: "ブランドカラー",
      enTitle: "Brand Color",
      summary: "ブランドカラーはAmebaらしさの視覚的表現の基調となるカラーパレットです。"
    }, {
      url: "/styles/color/ui/",
      title: "UIカラー",
      enTitle: "UI Color",
      summary: "UIカラーは、Amebaが提供するデジタルコンテンツの中で使用される、RGB色空間に最適化されたカラーパレットです。"
    }]} mdxType="ContentsList"></ContentsList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      